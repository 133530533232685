<template>
  <NeoModal
    eager
    :max-width="700"
    :title-header="$t('identityVerification')"
    scrollable
    @close="closeForm"
    @clickOutsideCallback="closeForm"
  >
    <ChangePassword v-if="openChangePassword" @close="closePasswordModal" />
    <v-card-text>
      <div class="checkout-flow">
        <CheckoutSteps
          :steps="steps"
          :stepActive="stepActive"
          :complete="$store.state.kycVerified"
        />
        <div class="checkout-flow-wrapper-steps">
          <div
            class="checkout-flow__layer"
            :class="{
              'checkout-flow__layer--visible':
                steps[stepActive].stepOrder === 0,
            }"
          >
            <Registration1VerifyEmail
              :profileEmail="$store.state.user.email"
              @emailVerified="emailVerified"
            />
          </div>
          <div
            class="checkout-flow__layer"
            :class="{
              'checkout-flow__layer--visible':
                steps[stepActive].stepOrder === 1,
            }"
          >
            <Registration2GetPhone
              v-if="stepActive === 1"
              :phoneNumber="this.userInfo.telephone.number"
              @validPhone="setValidPhone"
              @invalidPhone="setInvalidPhone"
            />
          </div>
          <div
            class="checkout-flow__layer"
            :class="{
              'checkout-flow__layer--visible':
                steps[stepActive].stepOrder === 2,
            }"
          >
            <Registration3VerifyPhone
              v-if="stepActive === 2"
              :phoneNumber="userInfo.telephone.number"
              @phoneVerified="phoneVerified"
            />
          </div>

          <div
            class="checkout-flow__layer"
            :class="{
              'checkout-flow__layer--visible':
                steps[stepActive].stepOrder === 3,
            }"
          >
            <v-form
              class="profile-modal-form mb-4"
              ref="personalForm"
              v-model="valid[stepActive]"
              lazy-validation
            >
              <div class="neo-text-field neo-text-field--with-avatar mb-8">
                <div>{{ $t("profilePhoto") }}</div>
                <div class="d-flex align-center">
                  <NeoAvatar
                    v-if="photoURL"
                    class="ml-2 mt-2"
                    size="63"
                    :photoURL="photoURL"
                    :noCache="false"
                  />
                  <v-file-input
                    :rules="[rules.maxSize]"
                    accept="image/png, image/jpeg, image/bmp"
                    :placeholder="$t('profilePhoto')"
                    append-icon="mdi-camera"
                    :label="$t('profilePhoto')"
                    @change="onChangeFileInput($event, 'avatar')"
                  ></v-file-input>
                </div>
              </div>
              <div class="change-password">
                <v-btn color="primary" @click="openPasswordModal">{{
                  $t("changePassword")
                }}</v-btn>
              </div>
              <div class="neo-text-field"></div>
              <v-divider class="profile-modal-form-item-block mb-4"></v-divider>
              <div class="neo-text-field">
                <div>Username:</div>
                <v-text-field
                  :disabled="!!$store.state.user.displayName"
                  ref="username"
                  v-model="userInfo.username"
                  label="Username"
                  required
                  :rules="[rules.required, rules.available]"
                  @keyup="isUsernameAvailable"
                  :messages="usernameTakenMsg"
                ></v-text-field>
              </div>
              <div class="neo-text-field">
                <div>{{ $t("phoneNumber") }}</div>
                <vue-tel-input-vuetify
                  :disabled="!!$store.state.user.phoneNumber"
                  autoformat
                  required
                  flat
                  solo
                  outlined
                  dense
                  disabledFetchingCountry
                  v-model="telephoneInputS"
                  :error="phoneError"
                  :preferredCountries="['JP']"
                  @input="onTelephoneInput"
                  :dropdownOptions="{
                    showFlags: false,
                    showDialCode: false,
                  }"
                  :inputOptions="{
                    showFlags: false,
                    showDialCodeInSelection: true,
                  }"
                  select-label="code"
                  append-icon="mdi-menu-down"
                  :label="$t('phoneNumber')"
                ></vue-tel-input-vuetify>
              </div>

              <div class="neo-text-field">
                <div>{{ $t("firstName") }}</div>
                <v-text-field
                  :disabled="$store.state.kycPending"
                  ref="firstName"
                  v-model="userInfo.firstName"
                  :rules="[rules.required]"
                  :label="$t('firstName')"
                ></v-text-field>
              </div>
              <div class="neo-text-field">
                <div>{{ $t("lastName") }}</div>
                <v-text-field
                  :disabled="$store.state.kycPending"
                  ref="lastName"
                  v-model="userInfo.lastName"
                  :rules="[rules.required]"
                  :label="$t('lastName')"
                ></v-text-field>
              </div>
              <div class="neo-text-field">
                <div>
                  {{ $t("nameKana") }}
                </div>
                <v-text-field
                  :disabled="$store.state.kycPending"
                  ref="nameKana"
                  v-model="userInfo.firstNameKana"
                  :label="$t('nameKana')"
                ></v-text-field>
              </div>
              <div class="neo-text-field">
                <div>{{ $t("surnameKana") }}</div>
                <v-text-field
                  :disabled="$store.state.kycPending"
                  ref="lastNameKana"
                  v-model="userInfo.lastNameKana"
                  :label="$t('surnameKana')"
                ></v-text-field>
              </div>
              <div class="neo-text-field">
                <div>{{ $t("birthdate") }}</div>
                <v-menu
                  :disabled="$store.state.kycPending"
                  v-model="menuBirth"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :disabled="$store.state.kycPending"
                      v-model="userInfo.birthdate"
                      :label="$t('birthdate')"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.required]"
                      messages="Must be 18 or over"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :disabled="$store.state.kycPending"
                    :locale="$store.state.lang"
                    v-model="userInfo.birthdate"
                    @input="menuBirth = false"
                    :max="maxDateOfBirth"
                    min="1900-01-01"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="neo-text-field mailing-list-checkbox">
                <v-checkbox
                  v-model="userInfo.mailingList"
                  :label="$t('subscribeMailingList')"
                >
                </v-checkbox>
              </div>
            </v-form>
          </div>
          <div
            class="checkout-flow__layer"
            :class="{
              'checkout-flow__layer--visible':
                steps[stepActive].stepOrder === 4,
            }"
          >
            <CommonSuccessCheckout
              class="mb-4"
              :text="$t('identityVerificationNotice')"
              color="success"
              icon="mdi-check-circle"
            />
          </div>
          <div
            class="checkout-flow__layer"
            :class="{
              'checkout-flow__layer--visible':
                steps[stepActive].stepOrder === 5,
            }"
          >
            <v-form
              class="profile-modal-form mb-4"
              ref="addressForm"
              v-model="valid[stepActive]"
              lazy-validation
            >
              <div class="neo-text-field">
                <div>{{ $t("addressLine1") }}</div>
                <v-text-field
                  ref="line1"
                  v-model="userInfo.address.line1"
                  :rules="[rules.required, rules.max]"
                  :label="$t('addressLine1')"
                  counter="25"
                ></v-text-field>
              </div>
              <div class="neo-text-field">
                <div>{{ $t("addressLine2") }}</div>
                <v-text-field
                  ref="line2"
                  v-model="userInfo.address.line2"
                  :rules="[rules.max]"
                  :label="$t('addressLine2')"
                  counter="25"
                ></v-text-field>
              </div>

              <div class="neo-text-field">
                <div>{{ $t("city") }}</div>
                <v-text-field
                  ref="city"
                  v-model="userInfo.address.city"
                  :rules="[rules.required]"
                  :label="$t('city')"
                  required
                ></v-text-field>
              </div>
              <div class="neo-text-field">
                <div>{{ $t("region") }}</div>
                <v-text-field
                  ref="region"
                  v-model="userInfo.address.region"
                  :rules="[rules.required]"
                  :label="$t('region')"
                  required
                ></v-text-field>
              </div>
              <div class="neo-text-field">
                <div>{{ $t("postcode") }}</div>
                <v-text-field
                  ref="postcode"
                  v-model="userInfo.address.postcode"
                  :rules="[rules.required]"
                  :label="$t('postcode')"
                  required
                ></v-text-field>
              </div>

              <div class="neo-text-field">
                <div>{{ $t("country") }}</div>
                <v-autocomplete
                  auto-select-first
                  ref="country"
                  v-model="userInfo.address.country"
                  :rules="[rules.required]"
                  :items="countries"
                  :label="$t('country')"
                  placeholder="Select..."
                  required
                ></v-autocomplete>
              </div>
            </v-form>
          </div>
        </div>
        <div class="checkout-flow__footer">
          <template v-if="stepActive === 0">
            <v-btn
              :disabled="!this.$store.state.user.emailVerified"
              color="primary"
              @click="forwardStep"
            >
              {{ $t("next") }}
            </v-btn>
          </template>
          <template v-if="stepActive === 1">
            <v-btn
              :disabled="this.$store.state.user.emailVerified"
              outlined
              color="primary"
              @click="backwardStep"
              >{{ $t("back") }}</v-btn
            >
            <v-btn
              :disabled="!this.validPhone"
              color="primary"
              @click="forwardStep"
            >
              {{ $t("next") }}
            </v-btn>
          </template>
          <template v-if="stepActive === 2">
            <v-btn
              :disabled="!!this.$store.state.user.phoneNumber"
              outlined
              color="primary"
              @click="backwardStep"
              >{{ $t("back") }}</v-btn
            >
            <v-btn
              :disabled="
                !this.$store.state.user.phoneNumber || !this.validPhone
              "
              color="primary"
              @click="forwardStep"
            >
              {{ $t("next") }}
            </v-btn>
          </template>
          <template v-if="stepActive === 3">
            <v-btn
              :disabled="!!this.$store.state.user.phoneNumber"
              outlined
              color="primary"
              @click="backwardStep"
              >{{ $t("back") }}</v-btn
            >
            <v-btn
              :disabled="!valid[stepActive]"
              color="primary"
              @click="forwardStep"
            >
              {{ $t("next") }}
            </v-btn>
          </template>
          <template v-if="steps[stepActive].id === 'verificationNotice'">
            <div>
              <div>
                <v-btn
                  class="verification-buy-button"
                  color="primary"
                  @click="goToBuy"
                >
                  {{ $t("buy") }}
                </v-btn>
              </div>
              <div class="verification-steps-buttons">
                <v-btn outlined color="primary" @click="backwardStep">{{
                  $t("back")
                }}</v-btn>
                <v-btn outlined color="primary" @click="forwardStep">
                  {{ $t("verify") }}
                </v-btn>
              </div>
            </div>
          </template>
          <template v-if="steps[stepActive].id === 'addressForm'">
            <v-btn outlined color="primary" @click="backwardStep">{{
              $t("back")
            }}</v-btn>
            <v-btn
              :disabled="!valid[stepActive]"
              color="primary"
              @click="submitAddress"
            >
              {{ $t("submit") }}
            </v-btn>
          </template>
        </div>
      </div>
    </v-card-text>
  </NeoModal>
</template>

<script>
import CheckoutSteps from "@/components/Organisms/CheckoutSteps";
import NeoAvatar from "@/components/Molecules/NeoAvatar";
import { firebase } from "@/helpers/firebase";
import UsersDS from "@/services/UsersDS";

const CommonSuccessCheckout = () =>
  import(
    /* webpackChunkName: "CommonSuccessCheckout" */ "@/components/Organisms/CommonSuccessCheckout"
  );

const ChangePassword = () =>
  import(
    /* webpackChunkName: "ChangePassword" */ "@/components/Organisms/ChangePassword"
  );
const Registration1VerifyEmail = () =>
  import(
    /* webpackChunkName: "Registration1VerifyEmail" */ "@/components/Molecules/Registration1VerifyEmail"
  );
const Registration2GetPhone = () =>
  import(
    /* webpackChunkName: "Registration2GetPhone" */ "@/components/Molecules/Registration2GetPhone"
  );
const Registration3VerifyPhone = () =>
  import(
    /* webpackChunkName: "Registration3VerifyPhone" */ "@/components/Molecules/Registration3VerifyPhone"
  );
const NeoModal = () =>
  import(/* webpackChunkName: "NeoModal" */ "@/components/Molecules/NeoModal");

export default {
  name: "ProfileUserRegistration",
  components: {
    CommonSuccessCheckout,
    ChangePassword,
    CheckoutSteps,
    Registration1VerifyEmail,
    Registration2GetPhone,
    Registration3VerifyPhone,
    NeoModal,
    NeoAvatar,
  },
  props: {
    info: Object,
  },
  data: () => ({
    openChangePassword: false,
    stepActive: 0,
    valid: [false, false, false, true, false, false],
    userValid: false,
    usernameTakenMsg: "",
    rules: {
      available: () => true || "",
      maxSize: (value) => !value || value.size < 2e6 || "Max 2 MB!",
      required: (value) => !!value || "Required.",
      max: (v) => v.length <= 25 || "Max 25 characters",
      email: (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
      number: (v) => Number.isInteger(Number(v)) || "Invalid telephone number",
    },
    photoURL: "",
    identityURL: "",
    fileData: {},
    menuBirth: false,
    countries: ["Japan"],
    userInfo: {
      firstName: "",
      lastName: "",
      firstNameKana: "",
      lastNameKana: "",
      birthdate: "",
      identityURL: "",
      username: "",
      mailingList: true,
      telephone: { country: undefined, value: undefined },
      default_pm: "",
      photoURL: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        region: "",
        postcode: "",
        country: "",
      },
    },
    errorMessages: "",
    formHasErrors: false,
    telephoneInputS: "",
    validPhone: false,
    phoneError: false,
    telephoneInput: {
      number: "",
      valid: false,
      country: "JP",
    },
    pendingKYC: false,
  }),
  watch: {
    info() {
      this.updateUserInfo();
    },
  },
  computed: {
    maxDateOfBirth() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 18);
      return date.toISOString().substr(0, 10);
    },
    steps() {
      let tempSteps = tempSteps = [
          { id: "verifyEmail", text: "", stepOrder: 0 },
          { id: "getPhone", text: "", stepOrder: 1 },
          { id: "verifyPhone", text: "", stepOrder: 2 },
          { id: "personalForm", text: "", stepOrder: 3 },
          { id: "addressForm", text: "", stepOrder: 5 },
        ];
      return tempSteps;
    },
  },
  methods: {
    goToBuy() {
      this.$emit("close");
      this.$router.push({ name: "artwork" });
    },
    openPasswordModal() {
      this.openChangePassword = true;
    },
    closePasswordModal() {
      this.openChangePassword = false;
    },
    setPendingKYC(value) {
      this.pendingKYC = value;
      if (this.pendingKYC)
        this.$store.commit("setVerified", { pending: true, verified: false });
    },
    kycError() {
      this.backwardStep();
    },
    emailVerified() {
      this.stepActive = Math.max(1, this.stepActive);
      this.valid[0] = true;
    },
    setInvalidPhone() {
      this.validPhone = false;
    },
    setValidPhone(phoneNumber) {
      this.validPhone = true;
      this.telephoneInput = phoneNumber;
      this.userInfo.telephone = phoneNumber;
      UsersDS.update(this.$store.state.user.uid, { telephone: phoneNumber });
    },
    async phoneVerified() {
      this.valid[1] = true;
      this.valid[2] = true;
      this.telephoneInputS = this.userInfo.telephone.number;
      let currentUser = await firebase.auth().currentUser;
      await currentUser.reload();
      currentUser = await firebase.auth().currentUser;
      if (currentUser.phoneNumber) {
        this.$store.commit("updateUser", currentUser);
      }

      if (this.stepActive < 3) this.forwardStep();
    },
    submitAddress() {
      this.$emit("submitAddress", {
        address: this.userInfo.address,
      });
      this.closeForm();
    },
    forwardStep() {
      if (this.stepActive < 3)
        this.stepActive = Math.min(this.stepActive + 1, this.steps.length);
      else if (this.stepActive === 3) {
        if (this.$refs[this.steps[this.stepActive].id].validate()) {
          this.stepActive = 4;
          this.$emit("submitPersonalInfo", {
            photos: this.fileData,
            userInfo: this.userInfo,
          });
        }
      } else if (this.stepActive === 4) {
        this.stepActive = 5;
        this.valid[this.stepActive] = true;
      } else if (this.stepActive === 5) {
        if (this.$refs[this.steps[this.stepActive].id].validate()) {
          this.stepActive = 6;
          this.$emit("submitAddress", {
            address: this.userInfo.address,
          });
        }
      }
    },
    backwardStep() {
      if (this.stepActive === 0) {
        this.stepActive = 0;
      } else {
        this.stepActive -= 1;
      }
    },
    async isUsernameAvailable() {
      let query = await UsersDS.checkUsername(this.userInfo.username);

      if (query.size > 0) {
        this.userValid = false;
        this.rules.available = false;
        this.usernameTakenMsg = this.$t("usernameTaken");
      } else {
        this.rules.available = true;
        this.userValid = true;
        this.usernameTakenMsg = "";
      }
    },
    telValidate(telnumber) {
      this.validatedphonenumber = telnumber.number;
      this.validphonenumber = telnumber.valid;
      return this.validphonenumber;
    },
    onTelephoneInput(formattedNumber, telephone) {
      if (!telephone) return;

      const { number, valid, country } = telephone;
      this.validPhone = valid;
      if (valid) {
        this.phoneError = false;
        this.userInfo.telephone = {
          number: number.e164,
          country: country.iso2,
          value: number.input,
        };
      }
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    imageToDataUri(img, width, height) {
      const canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      const ratio = height / width;
      let resizeWidth, resizeHeight;
      if (width > height) {
        resizeWidth = Math.min(width, 192);
        resizeHeight = Math.round(resizeWidth * ratio);
      } else {
        resizeHeight = Math.min(height, 192);
        resizeWidth = Math.round(resizeHeight / ratio);
      }

      // set its dimension to target size
      canvas.width = resizeWidth;
      canvas.height = resizeHeight;
      // draw source image into the off-screen canvas:
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      ctx.drawImage(canvas, 0, 0, resizeWidth, resizeHeight);
      ctx.drawImage(
        canvas,
        0,
        0,
        resizeWidth,
        resizeHeight,
        0,
        0,
        canvas.width,
        canvas.height
      );

      return canvas.toDataURL("image/jpeg", 0.95);
    },
    onChangeFileInput(selectedFile, fileType) {
      if (selectedFile) {
        this.fileData[fileType] = new Object();
        this.fileData[fileType].isNew = true;
        this.fileData[fileType].type = selectedFile.type;
        this.fileData[fileType].fileName = selectedFile.name;

        this.readFileAsync(selectedFile)
          .then((result) => {
            this.fileData[fileType].data = result;

            const blob = new Blob([result], { type: "image/jpg" });
            let src = URL.createObjectURL(blob);
            if (fileType === "identity") {
              this.identityURL = src;
            } else {
              this.photoURL = src;
            }

            const img = new Image();
            img.onload = function () {
              let height = img.height;
              let width = img.width;

              const srcCompressed = this.imageToDataUri(img, width, height);
              this.fileData["avatar"].data = srcCompressed;
            }.bind(this);
            img.src = src;
          })
          .catch((e) => {
            console.log(`e=${e}`);
          });
      }
    },
    closeForm() {
      if (this.$store.state.isVerified) {
        this.$emit("close");
      } else {
        this.$router.push({ name: "artwork" });
      }
    },

    // submit(close) {
    //   this.userInfo.valid = this.valid;
    //   this.$emit("input", {
    //     photos: this.fileData,
    //     userInfo: this.userInfo,
    //     address: this.address,
    //   });
    //   if (close) this.closeForm();
    // },
    updateUserInfo() {
      if (!this.userInfo.username) this.userInfo.username = "";
      for (let item in this.info) {
        if (item in this.userInfo) {
          this.userInfo[item] = this.info[item];
        }
        // else if (item in this.address) {
        //   this.address[item] = this.info[item];
        // }
      }
      if (this.userInfo.telephone) {
        this.telephoneInputS = this.userInfo.telephone.number;
        if (this.telephoneInputS) {
          this.telephoneInput = this.userInfo.telephone;
        }
      }
    },
  },
  beforeMount() {
    this.updateUserInfo();
    if (this.$store.state.user.phoneNumber) this.stepActive = 3;
    else if (this.$store.state.user.emailVerified) this.stepActive = 1;
    else this.stepActive = 0;
  },
};
</script>

<style lang="scss" scoped>
.verification-buy-button {
  display: grid;
  width: 100%;
  margin-bottom: 35px;
}
.verification-steps-buttons {
  display: flex;
  justify-content: center;
}
.change-password {
  display: flex;
  margin-left: auto;
  margin-block: auto;
  padding-bottom: 15px;
}
.profile-modal-form {
  padding-bottom: 00px;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 24px;
  row-gap: 8px;
  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.mailing-list-checkbox {
  display: end;
  align-items: end;
  justify-content: end;
  padding-bottom: 5px;
}
.profile-modal-form-item-block {
  grid-column: 1 / -1;
}

.checkout-flow {
  $this: &;
  height: 100%;
  display: flex;
  flex-direction: column;

  .checkout-flow-wrapper-steps {
    display: flex;
    width: 100%;
  }

  &__layer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: scale(0);
    transition: transform 300ms ease-in-out;
    width: 0;
    height: 0;
    &--visible {
      width: 100%;
      opacity: 1;
      transform: scale(1);
      height: auto;
    }
    > div:first-child {
      width: 100%;
    }
  }
  &__footer {
    margin-top: auto;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    .v-btn + .v-btn {
      margin-left: 24px;
    }
  }
}
.checkout-confirm-buttons {
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    .v-btn + .v-btn {
      margin-top: 16px;
      margin-left: 0;
    }
  }
}

.min-checkout-height {
  @media only screen and (min-width: 1200px) {
    min-height: 520px;
  }
}

.checkout-advise-modal-footer {
  display: flex;
  @media only screen and (max-width: 599px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > button + button {
      margin-top: 16px;
    }
  }
  @media only screen and (min-width: 600px) {
    flex-direction: row;
    padding: 16px;
    > button + button {
      margin-left: 16px;
    }
  }
}
</style>
